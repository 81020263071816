import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Form } from '../components/Form';
import FormSection from '../components/Form/FormSection';
import TextHero from '../components/TextHero';
import SocialIcons, { SocialItemsData } from '../components/SocialIcons';
import SiteMeta from '../components/SiteMeta';
import { useCookie } from 'react-use';

const SubmitMessage = ({ wasSuccessful }) => {

  return (
    <div className="demo-form__submit-view">
      <div className="demo-form__message">
        <h3 className="demo-form__submit-title">
          {wasSuccessful ? "Thank you!" : "We're sorry"}
        </h3>
        <p className="demo-form__submit-subtitle">
          {wasSuccessful ? "We look forward to showing you all the ways ProEdge can revolutionize your workforce." : "Something went wrong. Refresh the page and try again or come back later."}
        </p>
      </div>
      <div className="demo-form__social">
        <p>Let's connect</p>
        <SocialIcons
          items={SocialItemsData.filter((item) =>
            ['twitter', 'linkedin'].includes(item.slug),
          )}
          theme="light"
        />
      </div>
    </div>
  )
}

const ContactPage = ({ data }) => {

  const COOKIE_NAME = 'PWC_WFE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME);

  const {
    metaTitle,
    metaDescription: { metaDescription },
    heroTitle,
    heroBody: { heroBody },
    formSection
  } = data.contentfulContactUsPage;


  const TextHeroContent = {
    title: heroTitle,
    subtitle: heroBody
  }
  const RedirectURL = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
    if (typeof window !== 'undefined') {
      window.location = '/thank-you';
    }
    return null;
  }
  const handleFormSubmitSuccess = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
  }

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <TextHero {...TextHeroContent} />
      <FormSection>
        <Form onSubmitSuccess={() => RedirectURL()} {...formSection} />
      </FormSection>
    </Layout>
  )
}

export const query = graphql`
  query {
      contentfulContactUsPage(slug: {eq: "wfe-contact-page"})  {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroBody {
        heroBody
      }
      heroTitle
      formSection {
        ...Form
      }
    }
  }
`;

export default ContactPage;
